import React from "react"
import { graphql, Link } from "gatsby"
import parse from "html-react-parser"
import loadable from "@loadable/component"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { Button } from "../components/styles/Buttons";
import CategoryNavigation from "../components/CategoryNavigation";
import Footer from "../components/footer";
import Accordion from "../components/accordion";
import Icon from "../components/Icon";
import Bio from "../components/bio"
import ShareButtons from "../components/ShareButtons";

import {
  SectionContainerGridFourCols, 
  Flex, 
  SectionBlue, 
  BottomDivider, 
  SectionTitleContainer, 
  SectionWrapper, 
  SectionWrapperSmall, 
  SectionDarkBlue
} from '../components/styles/Sections';

import { Subheading, Notice } from "../components/styles/Headings";

const Header = loadable(() =>
  import(/* webpackPrefetch: true */ '../components/header'),
)
const BrandList = loadable(() => import('../components/brandList'), {
  fallback: <div>Laddar casinon...</div>,
})
const SliderComponent = loadable(() => import('../components/SliderComponent'), {
  fallback: <div>Laddar casinon...</div>,
})
const NewsComponent = loadable(() => import('../components/NewsComponent'), {
  fallback: <div>Laddar nyheter...</div>,
})
const ArticleComponent = loadable(() => import('../components/ArticleComponent'), {
  fallback: <div>Laddar guider...</div>,
})

const ToC = loadable(() => import('../components/TableOfContents'), {
  fallback: <div>Laddar innehåll...</div>,
})

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { postsToShow: 15 };
  }

  render() {
    const { data: { wpPage, allWpPost, allWpNyheter, allWpKampanj, allWpGuider, }, location } = this.props;
    const homePage = wpPage;
    const nyheter = allWpNyheter.nodes;
    const guider = allWpGuider.nodes;
    const campaigns = allWpKampanj.nodes;
    const posts = allWpPost.nodes;
    const brands = wpPage.casinolista && wpPage.casinolista.casinon;
    const year = new Date().getFullYear();
    const numberOfPosts = wpPage.casinolista.casinon ? brands.length : undefined;
    const seoTitle = homePage.seo ? homePage.seo.title : undefined;
    const canonicalUrl = homePage.seo ? "https://svenskaonlinecasinon.se" : undefined;
    const seoMetaDescription = homePage.seo ? homePage.seo.metaDesc : undefined;
    const ogImage = homePage.featuredImage ? homePage.featuredImage.node.sourceUrl : homePage.seo.opengraphImage.link;
    const pageTitle = "Populära casinon";
    const faqs = homePage.faq.faq ? homePage.faq.faq[0].faqSection : undefined;

    const showMore = () => {
      this.setState({
        postsToShow: numberOfPosts
      });
    }

    const schema = homePage.seo.schema.raw

    return (
      <Layout className="blog-post">
        <Seo 
          title={seoTitle} 
          description={seoMetaDescription}
          canonical={canonicalUrl} 
          modified={homePage.modified} 
          ogImage={ogImage}
          ogType={"website"}
          ogUrl="https://svenskaonlinecasinon.se"
          schema={schema}
          pageUrl={homePage.sidinformation.pageUrl}
       />  
        <Header title={homePage.title} isFrontPage={homePage.isFrontPage} headings={homePage.toc.items} description={homePage.sidinformation.bannerText} campaigns={campaigns} /> 
          <SectionBlue>
            <SectionWrapper>
              <Subheading id="topplista">{homePage.sidinformation.subtitle} {year}</Subheading>
              <Notice>Alla casinon i vår lista har svensk licens <Icon icon="lock-rounded" size={16} />och är noggrant granskade <Icon icon="check-circle" size={14} /></Notice>
              <CategoryNavigation location={location} pageTitle={pageTitle} />
              {brands && brands.slice(0, this.state.postsToShow).map((item, i) => (
                <BrandList item={item} key={i} count={i} />
              ))}
              {this.state.postsToShow < numberOfPosts && (
                <Button className="green large center" onClick={() => showMore()}>Ladda fler</Button>
              )}
            </SectionWrapper>
          </SectionBlue>
          <SectionWrapper>
            <SectionTitleContainer hasButton>
              <div>
                <h2 className="white">Senaste casinonyheterna</h2>
                <p>Här hittar du de senaste nyheterna om svenska casinon.</p>
              </div>
              <Link to="/nyheter"><Button className="blue right medium">Se alla nyheter</Button></Link>
            </SectionTitleContainer>
            <Flex>
              <div className="col-left">
                {nyheter.slice(0, 1).map((item, i) => (
                  <ArticleComponent item={item} featured={true} hideCategories={true} key={i} />
                ))}
              </div>
              <div className="col-right">
                {nyheter.slice(1).map((item, i) => (
                  <NewsComponent item={item} hideCategories={true} key={i} />
                ))}
              </div>
            </Flex>
            <BottomDivider />
          </SectionWrapper>
          <SectionDarkBlue>
            <SectionWrapper>
              <SectionTitleContainer hasButton className="nopadding">
                <div>
                  <h2 className="white">Guider</h2>
                  <p class="light">Oavsett om du är nybörjare eller proffs har vi något för dig. Läs våra guider för att finslipa ditt spelande.</p>
                </div>
                <Link to="/guider"><Button className="yellow right medium">Se alla guider</Button></Link>
              </SectionTitleContainer>
              <SectionContainerGridFourCols>
                {guider.map((item, i) => (
                  <ArticleComponent item={item} isFrontPage={true} hideCategories={true} key={i} />
                ))}
              </SectionContainerGridFourCols>
              <BottomDivider />
            </SectionWrapper>
          </SectionDarkBlue>
          <SectionWrapper>
            <SectionTitleContainer hasButton>
              <h2>Senast tillagda casinon</h2>
              <Link to="/nya-casinon"><Button className="blue right medium">Visa alla nya casino</Button></Link>
            </SectionTitleContainer>
              <SliderComponent items={posts} />
            <BottomDivider />
          </SectionWrapper>
       
        <SectionBlue>
          <SectionWrapperSmall>
            <Accordion questionsAnswers={faqs} />
          </SectionWrapperSmall>
        </SectionBlue>
        <ToC headings={homePage.toc.items} />
        {!!homePage.content && (
          <section id="main-content" className="containerNarrow">
            {parse(homePage.content)}
            <ShareButtons url={canonicalUrl} title="Dela sida:" />
            <Bio author={homePage.author.node} />
          </section>
        )}
        <Footer location={location} />
      </Layout>
    )
  }
}

export default HomePage;

export const pageSingleQuery = graphql`
  query HomePage {
    wpPage(isFrontPage: {eq: true}) {
      slug
      title
      content
      toc
      isFrontPage
      author {
        node {
          name
          description
          uri
          avatar {
            url
          }
        }
      }
      seo {
        title
        metaDesc
        canonical
        schema {
          raw
        }
        canonical
        opengraphSiteName
        opengraphType
        opengraphUrl
        opengraphImage {
          link
        }
      }
      featuredImage {
        node {
        	sourceUrl
        }
      }
      sidinformation {
        bannerText
        subtitle
      }
      faq {
        fieldGroupName
        faq {
          faqSection {
            faqAnswer
            faqQuestion
            fieldGroupName
          }
          fieldGroupName
        }
      }
      casinolista {
        casinon {
          ... on WpPost {
            title
            uri
            slug
            id
            affiliateLink {
              affiliateLink {
                ... on WpAffiliateTracker {
                  id
                  uri
                }
              }
            }
            casinoInformation {
              bonus
              betalningslosningar
              ribbon
              description
              description2
              description3
              disclaimer
              fieldGroupName
              freespins
              hemsidaUrl
              lanserades
              loggaBakgrundsfarg
              bonuspercent
              minimumDeposit
              pageUrl
              pageUrlTitle
              rating
              terms
              uttagstid
              wagering
            }
            categories {
              nodes {
                id
              }
            }
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData (
                      placeholder: BLURRED
                      quality: 100
                      width: 200
                      height: 120
                      layout: FULL_WIDTH
                    )
                  }
                }
              }
            }
         
          }
        }
      }
    }
    allWpKampanj(
      limit: 3 
      sort: { fields: [date], order: DESC}
    ) {
      nodes {
        uri
        date(formatString: "MMMM DD, YYYY")
        campaign {
          campaignBrand
          brandBackgroundColor
          endDate
          startDate
          campaignAffiliateTracker {
            ... on WpAffiliateTracker {
              id
              affiliateLinks {
                affiliateUrl
              }
              link
            }
          }
        }
        title
        author {
          node {
            name
            avatar {
              url
            }
            authorSocials {
              linkedin
            }
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData (
                  width: 65
                  height: 65
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
    allWpPost(
      sort: { fields: [date], order: DESC }
      limit: 6
    ) {
      nodes {
        title
        uri
        slug
        casinoInformation {
          bonus
          freespins
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 400
                  height: 400
                  placeholder: BLURRED
                  quality: 100
                )
              }
            }
          }
        }
        casinoInformation {
          bonus
          rating
        }
      }
    }
    allWpNyheter(
      sort: { fields: [date], order: DESC }
      limit: 5
    ) {
      nodes {
        excerpt
        uri
        date(locale: "sv-SE", formatString: "MMMM DD, YYYY")
        title
        author {
          node {
            name
            avatar {
              url
            }
          }
        }
        categories {
          nodes {
            name
            uri
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData (
                  placeholder: BLURRED
                  width: 1008
                  height: 600
                )
              }
            }
          }
        }
      }
    }
    allWpGuider(
      sort: { fields: [date], order: DESC }
      limit: 4
    ) {
      nodes {
        excerpt
        uri
        date(locale: "sv-SE", formatString: "MMMM DD, YYYY")
        title
        author {
          node {
            name
            avatar {
              url
            }
          }
        }
        featuredImage {
          node {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 234
                  height: 135
                  placeholder: BLURRED
                )
              }
            }
          }
        }
      }
    }
  }
`


